<template>
  <section class="m-setting-clause m-scroll">
    <!-- 头部导航 -->
    <m-header title="条款" ></m-header>
    <div class="main">
        <p>
            退货退款规则
        </p>
        <p>
        <strong>
            <span>&nbsp;</span>
        </strong>
        </p>
        <p>
        <span>感谢您在Mooby Yoho购物</span>
        </p>
        <p>
        <span>如果您对您的购买不完全满意，可在此获得帮助</span>
        </p>
        <p>
        <span>&nbsp;</span>
        </p>
        <p>
        <strong>
            <span class="text-f">1.</span>
        </strong>
        <strong>
            <span>质量问题</span>
        </strong>
        </p>
        <p>
        <span>非人为损坏，产品本身存在质量缺陷，请提交售后申请，保证产品包装无损坏，产品上没有人为破损，划痕。请在获取产品退货码后，一同递交给我们提供给您的物流电话，或自行送回，平台将承担退回运费，MOOBYYOHO仓库将在收到商品后，确认商品符合退货条款规则无误，为您安排退货退款，退款金额将在收到产品后的3个工作日内返还到您的支付账户中，微信，支付宝支付的客户，返还金额将根据当日汇率计算后返还。</span>
        </p>
        <p>
        <span>&nbsp;</span>
        </p>
        <p>
        <strong>
            <span class="text-f">2.</span>
        </strong>
        <strong>
            <span>运输破损</span>
        </strong>
        </p>
        <p>
        <span>请在收到包裹后，与物流配送人员现场确认，包裹外包装无严重损坏，破损，开箱等痕迹后，在确认收货。如有以上几种情况，请拒绝收货。平台将在收取您的返回商品后，为您安排退款或重新安排发货。如选择退款，退款金额将在收到产品后的3个工作日内返还到您的支付账户中，微信，支付宝支付的客户，返还金额将根据当日汇率计算后返还。</span>
        </p>
        <p>
        <span>&nbsp;</span>
        </p>
        <p>
        <strong>
            <span class="text-f">3.</span>
        </strong>
        <strong>
            <span>发错商品</span>
        </strong>
        </p>
        <p>
        <span>请保证所有产品包装完好，未被使用。请在提交申请售后服务后，获取退货码，联系我们给您提供的物流电话，预约取货时间或自行送回，平台将承担退回运费。MOOBYYOHO仓库将在收到商品后，确认商品符合退货条款规则无误，为您安排退货退款，退款金额将在收到产品后的3个工作日内返还到您的支付账户中，微信，支付宝支付的客户，返还金额将根据当日汇率计算后返还。</span>
        </p>
        <p>
        <span>&nbsp;</span>
        </p>
        <p>
        <strong>
            <span class="text-f">4.</span>
        </strong>
        <strong>
            <span>漏发商品</span>
        </strong>
        </p>
        <p>
        <span>请您在申请售后完毕后，通过邮箱联系我们的客服人员，待确认后，我们将为您补发漏发产品。您无需支付相关物流费用。</span>
        </p>
        <p>
        <span>&nbsp;</span>
        </p>
        <p>
        <strong>
            <span class="text-f">5.</span>
        </strong>
        <strong>
            <span>特价产品</span>
        </strong>
        </p>
        <p>
        <span>特价产品可以进行退货。</span>
        </p>
        <p>
        <span>&nbsp;</span>
        </p>
        <p>
        <strong>
            <span class="text-f">6.</span>
        </strong>
        <strong>
            <span>退货规则</span>
        </strong>
        </p>
        <p>
        <span>非质量，错发，漏发原因退货，请在收到商品7日内，点击退货申请后，按照要求详细填写退货申请表，并确保商品包装完好，产品未被使用，获取退货码后，您需要联系物流公司，将产品邮寄回MOOBYYOHO仓库，您将自行承担邮寄费用，MOOBYYOHO仓库将在收到回邮商品后，确认商品满足退货条款规则无误，为您安排退货退款退款金额将在收到产品后的3个工作日内返还到您的支付账户中，微信，支付宝支付的客户，返还金额将根据当日汇率计算后返还。</span>
        </p>
        <p>
        <span>&nbsp;</span>
        </p>
        <p>
        <strong>
            <span class="text-f">7.</span>
        </strong>
        <strong>
            <span>补充规则</span>
        </strong>
        </p>
        <p>
        <span>如您购买的商品有问题申请退货退款，请您确保赠品完好且一并退回，因买家原因导致赠品无法退回，或退回途中损毁，丢失的，卖家可按赠品价值从交易货款中扣除相应价款。</span>
        </p>
        <p>
        <strong>
            <span>重要提示(以下品类不支持7天无忧退货)</span>
        </strong>
        </p>
        <p>
        <strong>
            <span>不支持7天无忧退货的商品（不支持退货退款的商品）</span>
        </strong>
        </p>
        <p>
        <strong>
            <span>&nbsp;</span>
        </strong>
        </p>
        <p>
        <span>①定制类商品：个性定制，设计服务</span>
        </p>
        <p>
        <span>②活鲜易腐类商品：鲜花绿植，水产肉类，新鲜蔬果。</span>
        </p>
        <p>
        <span>③在线下载或者消费者拆封的音像制品，计算机软件等数字化商品。</span>
        </p>
        <p>
        <span>④交付的报纸，期刊。</span>
        </p>
        <p>
        <span>⑤拆封后影响人身安全或者生命健康的商品，或者拆封后易导致商品&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 品质发生改变的商品。（贴身用品，食品，保健品，成人用品等）</span>
        </p>
        <p>
        <span>（商家表示支持七天无忧退货除外）</span>
        </p>
        <p>
        <span>⑥一经激活或者试用后价值贬损较大的商品，商用商品，或者短期内价值频繁波段的商品（智能商品，商用厨电，黄金等），商家表示支持七天无忧退货除外。</span>
        </p>
        <p>
        <span>通用完好标准指：</span>
        </p>
        <p>
        <span>①买家应当确保退回商品和相关配（附）件（如吊牌、说明书、三包卡等）齐全，并保持原有品质、功能，无受损、受污、刮开防伪、产生激活（授权）等情形，无难以恢复原状的外观类使用痕迹、不合理的个人数据使用痕迹；</span>
        </p>
        <p>
        <span>②商品的外包装为商品不可分割的组成部分，退回时应当齐全、无严重破损、受污；</span>
        </p>
        <p>
        <span>③赠品遗失或破损、发票遗失不影响商品退货。赠品破损或遗失作折价处理，发票遗失由买家承担相应税款。</span>
        </p>
        <p>
        <span>④如退还商品不属于质量问题，我们会自动扣除相应的取货费用。</span>
        </p>
        <p>
        <strong>
            <span>注：“外包装”系指生产厂商原包装（最小销售单元），不含商家或物流自行二次封装。</span>
        </strong>
        </p>
        <p>
        <strong>
            <span>&nbsp;</span>
        </strong>
        </p>
        <p>
        <strong>
            <span>特殊完好标准：</span>
        </strong>
        </p>
        <p>
        <span>①运动鞋类：（若有）防盗扣，防损贴且不影响合理试用，不可剪掉，撕毁。</span>
        </p>
        <p>
        <span>②洗护清洁剂/卫生巾/纸/香薰：洗护类（若有）商品一次性密封包装未拆封。</span>
        </p>
        <p>
        <span>③美发护发/假发：美发护发类（若有）商品一次性密封包装未拆封。</span>
        </p>
        <p>
        <span>④彩妆/香水/美妆工具:（若有）商品一次性密封包装未拆封。</span>
        </p>
        <p>
        <span>⑤数码家电行业（除相机，大家电）：1.进网许可证未损坏（毁）2.未有浸液</span>
        </p>
        <p>
        <span>⑥数码相机/单反相机/摄像机:单反相机快门不超过20次。</span>
        </p>
        <p>
        <span>⑦大家电：1.液晶电视，空调允许简单通电调试，不得上墙安装使用。2.洗衣机不得过水使用。</span>
        </p>
        <p>
        <span>⑧ZIPPO瑞士军刀眼镜：ZIPPO类商品棉芯不得注油使用，打火轮，打火石无明显磨损。</span>
        </p>
        <p>
        <span>⑨模玩/动漫/COS/桌游:模型，手办，兵人，BJD类商品内包装（若有）胶条，塑封不可拆。</span>
        </p>
        <p>
        <span>⑩电子元器件：1.未进行过焊接操作 2.集成电路未在IC底座上安装 3.易耗品（导热硅脂/导热膏/电子胶/封罐胶/密封剂/粘合剂，导电胶，导电墨水）一次性密封包装未拆封。</span>
        </p>
        <p>
        <span>&nbsp;</span>
        </p>
        <p>
        <strong>
            <span>联系我们</span>
        </strong>
        </p>
        <p>
        <span>如果您对我们的退货退款条款有任何疑问，请与我们联系：</span>
        </p>
        <p>
        <span>电子邮件：customerservice@moobyyoho.com</span>
        </p>
        <p>
        <span>退货及退款条款网址：www.moobyyoho.com</span>
        </p>
        <p>
        <span>若要获得商品退货编号(RMN)，请与我们联系：</span>
        </p>
        <p>
        <span>电子邮件：customerservice@moobyyoho.com</span>
        </p>
        <p>
        <span>将产品及其原始包装和RMN编号发送到：Unit 133-11121 Horseshoe Way Richmond, BC V7A 5G7, Canada</span>
        </p>
        <p>
        <span>&nbsp;</span>
        </p>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

export default {
  name:'Clause',
  components:{ MHeader },
  data(){
    return {
      
    }
  }
}
</script>

<style lang="less" scoped>
.m-setting-clause {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow-y: auto;
  .main {
    margin-top: 12px;
    width: 100%;
    padding: 0 16px;
    font-size: 16px;
    >p:nth-child(1){font-size:19px;text-align: center;font-size: 30px;margin-bottom:50px;font-weight: 600;}
    >p:nth-child(2) span {
        font-size: 19px;
    }
    .text-f {
        font-size: 16px;
    }
  }
}
</style>